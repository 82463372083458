/* eslint-disable react-hooks/exhaustive-deps */
import {Theme} from '@mui/material'
import {makeStyles} from '@mui/styles'

import {PageContainer} from '../../components/layout/page-container'
import {ChartsTool} from '../../utils'
import {ChartCard} from './chart-card'
import {featuredList} from './featured-list'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  galleryContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  chartsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 224px)',
    marginTop: theme.spacing(2),
    justifyContent: 'center',
    justifySelf: 'center',
    width: '100%',
  },
  moreButton: {
    margin: theme.spacing(2),
    width: '240px',
  },

  spacer: {
    maxWidth: '316px',
    minWidth: '120px',
  },
}))

export function ChartsGallery() {
  const chartList = featuredList
  const classes = useStyles()

  return (
    <PageContainer pbiTipsTool={ChartsTool}>
      <div className={classes.root}>
        <div className={classes.chartsContainer}>
          {chartList &&
            chartList.map((guid, index) => (
              <ChartCard key={`palette-${index}`} guid={guid} />
            ))}
        </div>
      </div>
    </PageContainer>
  )
}
