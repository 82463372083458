import {Card, CardActionArea, Theme, CardMedia} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {sendGoogleAnalytics} from '../../utils/analytics-helper'

const useStyles = makeStyles((theme: Theme) => ({
  chart: {
    borderRadius: '2px',
    margin: theme.spacing(1),
  },
  media: {},
}))

export function ChartCard(props: ChartCardProps) {
  const {guid} = props
  const svgFile = `https://pbitipsblob.blob.core.windows.net/pbivizfiles/${guid}.svg`
  const pbiFile = `https://pbitipsblob.blob.core.windows.net/pbivizfiles/${guid}.pbiviz`

  const classes = useStyles()

  function handleDownload() {
    sendGoogleAnalytics('downloadPBIviz', `${guid}`)
    window.open(pbiFile)
    // axios.post('/chart-download', guid)
  }

  return (
    <Card className={classes.chart}>
      <CardActionArea onClick={() => handleDownload()}>
        <CardMedia>
          <img
            src={`${svgFile}`}
            alt='Download Viz file'
            width='200px'
            height='200px'
          ></img>
        </CardMedia>
      </CardActionArea>

      {/* <Typography variant='h6' align='left' className={classes.title}>
        {title}
      </Typography> */}
    </Card>
  )
}

type ChartCardProps = {
  guid: string
}
