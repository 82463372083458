import {Card, CardActionArea, CardMedia, Theme} from '@mui/material'
import {Palette} from './palette'
import {PaletteObject} from '../../models'
import {makeStyles} from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  palette: {
    height: '80px',
    borderRadius: '2px',
    margin: theme.spacing(1),
  },
  media: {
    height: '80px',
  },
}))

export function PaletteCard(props: PaletteCardProps) {
  const {paletteObj, onDownloadClicked} = props

  const classes = useStyles()
  return (
    <Card className={classes.palette}>
      <CardActionArea onClick={() => onDownloadClicked(paletteObj)}>
        <CardMedia className={classes.media}>
          <Palette palette={paletteObj.palette} />
        </CardMedia>
      </CardActionArea>

      {/* <Typography variant='h6' align='left' className={classes.title}>
        {props.title}
      </Typography> */}
    </Card>
  )
}

type PaletteCardProps = {
  paletteObj: PaletteObject
  onDownloadClicked: (palette: PaletteObject) => void
}
