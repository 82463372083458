import {Theme} from '@emotion/react'
import {AppBar, Button, Drawer, Grid, Toolbar} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {useState} from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: '80%',
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    // background: '#2b2b2b',
  },
  placeholder: {
    height: '300px',
  },
}))
export function Footer(props: FooterProps) {
  const {onDownloadClicked} = props
  const classes = useStyles()
  return (
    <AppBar position='fixed' className={classes.appBar}>
      <Toolbar variant='regular'>
        <Grid
          container
          direction='row'
          alignItems='center'
          justifyContent='center'
        >
          <Button
            variant='contained'
            size='medium'
            color='secondary'
            onClick={onDownloadClicked}
          >
            Download File
          </Button>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}
export type FooterProps = {
  onDownloadClicked: () => void
}

export function FooterBar() {
  const classes = useStyles()
  const [drawerOpen, setDrawerOpen] = useState(false)
  return (
    <>
      <Drawer
        anchor='bottom'
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        className={classes.drawer}
      >
        {/* <FileForm /> */}
        <div className={classes.placeholder} />
      </Drawer>
      <Footer onDownloadClicked={() => setDrawerOpen(true)} />
    </>
  )
}
