import {ReactNode} from 'react'
import {makeStyles} from '@mui/styles'
import {Button, Paper, Theme, Typography} from '@mui/material'
import {PbiTipsTool} from '../../utils'
import {StackedAdContainer} from '..'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  contentContainer: {
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    flexGrow: 6,
  },
  adsContainer: {
    flexBasis: '372px',
    flexGrow: 3,
    margin: theme.spacing(3),
    marginLeft: theme.spacing(2),
  },
  fixedAdsContainer: {
    position: 'fixed',
    backgroundColor: '#fcfcfc',
  },
  gallery: {
    width: '100%',
  },
  headline: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  buttonLink: {marginLeft: theme.spacing(2)},
}))

export function PageContainer(props: PageContainerProps) {
  const {children, pbiTipsTool} = props
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>
        <div className={classes.headline}>
          <Typography variant='h6' align='center' sx={{marginRight: 2}}>
            {pbiTipsTool.assetName} created with the {pbiTipsTool.toolName}
          </Typography>
          <Button
            variant='contained'
            disableElevation
            className={classes.buttonLink}
            color='primary'
            href={pbiTipsTool.url}
          >
            Create my own
          </Button>
        </div>
        <div className={classes.gallery}>{children}</div>
      </div>
      <div className={classes.adsContainer}>
        <Paper className={classes.fixedAdsContainer}>
          <StackedAdContainer />
        </Paper>
      </div>
    </div>
  )
}

type PageContainerProps = {
  children: ReactNode
  pbiTipsTool: PbiTipsTool
}
