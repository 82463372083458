import {themeColors} from './theme-colors'
import {createTheme} from '@mui/material'

export const powerbi_tips_theme = createTheme({
  palette: {
    primary: {
      main: themeColors.themeDarkBlue,
    },
    secondary: {
      main: themeColors.themeYellow,
    },
  },
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
  },
})
