import * as ReactGA from 'react-ga'
const analyticsKey = 'UA-75735010-1'
ReactGA.initialize(analyticsKey)

export function sendGoogleAnalytics(action: string, value: string) {
  const ga = ReactGA.ga()
  ga('create', analyticsKey, 'auto', 'galleryTracker')
  ga('galleryTracker.send', 'event', 'Gallery', action, value)
}

export function sendGAPageView(path: string) {
  ReactGA.pageview(path)
}
