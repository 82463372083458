import {PaletteGallery} from './palettes/palette-gallery'
import {ChartsGallery} from './charts/chart-gallery'

export const paths = ['/', '/charts']

// prettier-ignore
export const pages = [
  {path: paths[0], title: 'Palette', example: PaletteGallery},
  {path: paths[1], title: 'Charts', example: ChartsGallery},
]
