import {useState, useEffect} from 'react'
import {Button, Theme, Dialog} from '@mui/material'
import {Palette} from './palette'
import {PbiReport} from '../../components/power-bi-report'
import {sendGoogleAnalytics} from '../../utils/analytics-helper'
import axios from 'axios'
import {makeStyles} from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    margin: 'auto',
  },
  popup: {
    minWidth: '600px',
    width: '800px',
    overflow: 'hidden',
  },
  palette: {
    height: '24px',
    width: '100%',
  },
  vizContainer: {
    backgroundColor: '#ffffff',
    height: '70vh',
  },
  report: {
    height: '100%',
  },
  topArea: {
    display: 'flex',
    alignItems: 'center',

    flexDirection: 'column',
  },
  button: {
    width: '200px',
    margin: theme.spacing(1),
  },
  exampleTitle: {
    marginLeft: theme.spacing(4),
  },
  actionArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2),
  },
}))

export function PalettePreview(props: PalettePreviewProps) {
  const classes = useStyles()
  const {onClose, shouldOpen, palette, paletteID} = props
  const themeName = 'PowerBI.tips - theme'

  const [open, setOpen] = useState(false)
  useEffect(() => {
    setOpen(shouldOpen)
  }, [shouldOpen])

  const handleClose = () => {
    setOpen(false)
    onClose()
  }
  const handleDownload = () => {
    let payload = {
      name: 'theme created by powerbi.tips https://themes.powerbi.tips',
      dataColors: [...palette],
    }

    saveFile(payload)
    sendGoogleAnalytics('downloadPalette', `${paletteID}`)
  }

  // TODO: Put this in a util
  const saveFile = (payload: PalettePayload) => {
    let {...json} = payload
    let filename = `${themeName}.json`
    let contentType = 'application/json;charset=utf-8;'

    let a = document.createElement('a')
    a.download = filename
    a.href =
      'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(json))
    a.target = '_blank'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)

    postFile({id: paletteID})
  }

  // TODO: Put this in a util
  const postFile = (json: PostDownloadPayload) => {
    axios({
      method: 'post',
      url: '/api/download-palette',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(json),
    })
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='download-palette'
      open={open}
      maxWidth='xl'
      className={classes.dialog}
    >
      <div className={classes.popup}>
        <div className={classes.topArea}>
          <div className={classes.palette}>
            <Palette palette={palette} />
          </div>
        </div>
        <PaletteExample palette={palette} />
        <div className={classes.actionArea}>
          <Button
            variant='outlined'
            className={classes.button}
            sx={{
              margin: 1,
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            disableElevation
            color='secondary'
            sx={{
              margin: 1,
            }}
            className={classes.button}
            onClick={handleDownload}
          >
            Download
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export function PaletteExample(props: PaletteExampleProps) {
  const classes = useStyles()
  const {palette} = props

  return (
    <div className={classes.vizContainer}>
      <PbiReport className={classes.report} palette={palette} />
    </div>
  )
}
type PalettePreviewProps = {
  onClose: () => void
  shouldOpen: boolean
  palette: string[]
  paletteID: string
}
type PaletteExampleProps = {
  palette: string[]
}
type PalettePayload = {
  name: string
  dataColors: string[]
}
type PostDownloadPayload = {
  id: string
}
