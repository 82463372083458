/* eslint-disable react-hooks/exhaustive-deps */
import {Theme} from '@mui/material'

import {useEffect, useState, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'

import {fetchColors as createFetchColorsAction} from '../../redux/actions'
import {testPalettes, ThemeGeneratorPalette} from '../../utils'
import {PageContainer} from '../../components/layout/page-container'
import {PaletteCard, PalettePreview} from '.'
import {AzureContinuationToken, PaletteObject} from '../../models'
import {makeStyles} from '@mui/styles'
import {Box} from '@mui/system'

const config = require('./../../config.json')

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  galleryContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  paletteContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 224px)',
    marginTop: theme.spacing(2),
    justifyContent: 'center',
    justifySelf: 'center',
    width: '100%',
  },
  moreButton: {
    margin: theme.spacing(2),
    width: '240px',
  },
}))

export function PaletteGallery() {
  const dispatch = useDispatch()
  let {palettes, continuationToken, dayModifier} = useSelector((state: any) => {
    return state.dataColors
  })

  if (config.useFakePalettes) palettes = testPalettes

  const classes = useStyles()
  const [previewOpen, setPreviewOpen] = useState(false)
  const [palettePreview, setPalettePreview] = useState<string[]>([])
  const [paletteID, setPaletteID] = useState<string>('')

  const lastAcknowledgedScrollPositionRef = useRef<number>(0)
  const paletteContainerRef = useRef<HTMLInputElement | null>(null)

  function fetchColors(
    continuationToken: AzureContinuationToken | null,
    dayModifier: number
  ) {
    dispatch(createFetchColorsAction(continuationToken, dayModifier))
  }

  function handleDownloadClicked(paletteObj: PaletteObject) {
    setPalettePreview(paletteObj.palette)
    setPaletteID(paletteObj.id)
    setPreviewOpen(true)
  }

  useEffect(() => {
    if (palettes.length) return

    fetchColors(null, dayModifier)

    document.addEventListener('scroll', FetchColorsOnScrollToBottom, false)

    return function cleanup() {
      document.removeEventListener('scroll', FetchColorsOnScrollToBottom)
    }
  }, [])

  useEffect(() => {
    // watch for state palette change, grab more data on initial load if we don't have a full window
    if (
      paletteContainerRef?.current?.offsetHeight !== undefined &&
      paletteContainerRef?.current?.offsetHeight < window.innerHeight
    ) {
      fetchColors(continuationToken, dayModifier)
    }
  }, [palettes])

  function FetchColorsOnScrollToBottom() {
    var minimumScrollDistanceToCheck = 100
    var endOfScrollFetchTolerance = 200
    var newPosition = window.pageYOffset

    var shouldHandleScroll =
      Math.abs(newPosition - lastAcknowledgedScrollPositionRef.current) >
      minimumScrollDistanceToCheck

    if (shouldHandleScroll) {
      var lastPosition = lastAcknowledgedScrollPositionRef.current
      lastAcknowledgedScrollPositionRef.current = newPosition

      var windowHeight = window.innerHeight
      var isScrollingDown = newPosition > lastPosition
      var isCloseToBottomOfScreen =
        Math.abs(
          newPosition +
            windowHeight -
            (paletteContainerRef?.current?.offsetHeight ?? 0)
        ) < endOfScrollFetchTolerance

      if (isScrollingDown && isCloseToBottomOfScreen) {
        fetchColors(continuationToken, dayModifier)
      }
    }
  }

  return (
    <PageContainer pbiTipsTool={ThemeGeneratorPalette}>
      <div className={classes.root}>
        <div className={classes.galleryContainer}>
          <Box className={classes.paletteContainer} ref={paletteContainerRef}>
            {palettes &&
              palettes.map((paletteObj: PaletteObject, index: number) => (
                <PaletteCard
                  key={`palette-${index}`}
                  paletteObj={paletteObj}
                  onDownloadClicked={handleDownloadClicked}
                />
              ))}
            <PalettePreview
              shouldOpen={previewOpen}
              palette={palettePreview}
              paletteID={paletteID}
              onClose={() => setPreviewOpen(false)}
            />
          </Box>
        </div>
      </div>
    </PageContainer>
  )
}
