import React, {useEffect, useState, useRef} from 'react'
import {PowerBIEmbed} from 'powerbi-client-react'
import {models, Report} from 'powerbi-client'
import axios from 'axios'
import {CircularProgress} from '@mui/material'
import {EmbedAccess} from '../../models'

export function PbiReport(props: PbiReportProps) {
  const {className, palette} = props

  const [embedAccess, setEmbedAccess] = useState<EmbedAccess | null>(null)

  const reportRef = useRef<Report>()

  let theme = {
    // name: "Valentine's Day",
    // dataColors: [
    //   '#990011',
    //   '#cc1144',
    //   '#ee7799',
    //   '#eebbcc',
    //   '#cc4477',
    //   '#cc5555',
    //   '#882222',
    //   '#A30E33',
    // ],
    // background: '#FFFFFF',
    // foreground: '#ee7799',
    // tableAccent: '#990011',
    name: 'powerbi.tips - theme',
    dataColors: palette,
    // background: '#FFFFFF',
    // foreground: '#ee7799',
    // tableAccent: '#990011',
  }
  useEffect(() => {
    async function fetchData() {
      try {
        let res = await axios({
          method: 'post',
          url: '/api/embed-access?',
        })
        setEmbedAccess(res.data)
      } catch (e) {
        console.log('Error trying to embed report', e)
      }
    }
    fetchData()
  }, [])

  if (!embedAccess) {
    return (
      <div className={className} style={{padding: '20px'}}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <PowerBIEmbed
      embedConfig={{
        type: 'report', // Supported types: report, dashboard, tile, visual and qna
        id: embedAccess.id,
        embedUrl: embedAccess.embedUrl,
        accessToken: embedAccess.accessToken,
        tokenType: models.TokenType.Embed,
        settings: {
          panes: {
            filters: {
              expanded: false,
              visible: false,
            },
            pageNavigation: {
              visible: false,
            },
          },
          // background: models.BackgroundType.Transparent,
        },
        // Adding theme attribute to the config, will apply the theme on load
        theme: {themeJson: theme},
      }}
      eventHandlers={
        new Map([
          [
            'loaded',
            function () {
              console.log('Report loaded')
            },
          ],
          [
            'rendered',
            function () {
              console.log('Report rendered')
            },
          ],
          [
            'error',
            function (event) {
              console.log(event?.detail)
            },
          ],
        ])
      }
      cssClassName={className}
      getEmbeddedComponent={embeddedReport => {
        reportRef.current = embeddedReport as Report
      }}
    />
  )
}

export type PbiReportProps = {
  className?: string
  palette: string[]
}
