import React from 'react'
import {Route} from 'react-router-dom'

import {pages} from './pages'

export function Routes() {
  return (
    <>
      {/* <Route exact path='/' component={HomePage} /> */}

      {pages.map((page: any) => (
        <Route
          exact={page.path === '/'}
          key={page.path}
          path={page.path}
          component={page.example}
        />
      ))}
    </>
  )
}
