//import {useRouteMatch} from 'react-router-dom'
import {
  Icon,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  SvgIcon,
  Theme,
  Typography,
  Box,
} from '@mui/material'
import {alpha, useTheme} from '@mui/system'
import {ReactNode} from 'react'
import {makeStyles} from '@mui/styles'
import gallerySvg from '../../images/nav-icons/gallery-icon.svg'
import chartsSvg from '../../images/nav-icons/charts-icon.svg'
import clothingSvg from '../../images/nav-icons/clothing-icon.svg'
import daxSvg from '../../images/nav-icons/dax-icon.svg'
import modelSvg from '../../images/nav-icons/model-icon.svg'
import {
  Twitter as TwitterIcon,
  Facebook as FacebookIcon,
  LinkedIn as LinkedInIcon,
  YouTube as YouTubeIcon,
  Language as LanguageIcon,
  Podcasts as PodcastsIcon,
  FormatPaint,
} from '@mui/icons-material'
const useStyles = makeStyles((theme: Theme) => ({
  navSection: {},
  listStyle: {
    margin: theme.spacing(25),
  },
  root: {
    width: '260px',
    maxWidth: 360,
  },

  item: {
    padding: 0,
  },

  drawerSocials: {
    position: 'absolute',
    bottom: 0,
  },

  imageIcon: {
    height: theme.spacing(5),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

export {PbiTipsToolsLinks, PbiTipsSocialLinks}

function PbiTipsToolsLinks() {
  return (
    <List style={{width: '260px'}}>
      <NavigationSection title='Tools'>
        <NavigationLink
          icon={<SvgIcon component={FormatPaint} />}
          path='https://themes.powerbi.tips'
          title='Themes'
        />
        <NavigationLink
          icon={
            <Icon>
              <img src={chartsSvg} alt='Charts Img' />{' '}
            </Icon>
          }
          path='https://charts.powerbi.tips'
          title='Charts'
        />

        <NavigationLink
          icon={
            <Icon>
              <img src={gallerySvg} alt='Gallery Img' />{' '}
            </Icon>
          }
          path='https://gallery.powerbi.tips'
          title='Gallery'
        />

        <NavigationLink
          icon={
            <Icon>
              <img src={daxSvg} alt='Dax Img' />{' '}
            </Icon>
          }
          path='https://dax.powerbi.tips/'
          title='Dax Templates'
        />
        <NavigationLink
          icon={
            <Icon>
              <img src={modelSvg} alt='Model Img' />{' '}
            </Icon>
          }
          path='https://model.powerbi.tips/'
          title='Model'
        />
      </NavigationSection>

      <NavigationSection title='Links'>
        <NavigationLink
          icon={<SvgIcon component={LanguageIcon} />}
          path='https://powerbi.tips/'
          title='Website'
        />
        <NavigationLink
          icon={<SvgIcon component={YouTubeIcon} />}
          path='https://www.youtube.com/powerbitips'
          title='YouTube'
        />
        <NavigationLink
          icon={<SvgIcon component={PodcastsIcon} />}
          path='https://powerbi.tips/explicit-measures-power-bi-podcast/'
          title='Podcasts'
        />
        <NavigationLink
          icon={
            <Icon>
              <img src={clothingSvg} alt='Clothing Img' />{' '}
            </Icon>
          }
          path='https://swag.powerbi.tips/'
          title='Clothing'
        />
      </NavigationSection>
    </List>
  )
}

function PbiTipsSocialLinks() {
  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
      }}
    >
      <NavigationLinkSocials
        icon={<SvgIcon component={FacebookIcon} />}
        path='https://www.facebook.com/PowerBI.Tips'
      />
      <NavigationLinkSocials
        icon={<SvgIcon component={TwitterIcon} />}
        path='https://twitter.com/powerbitips'
      />
      <NavigationLinkSocials
        icon={<SvgIcon component={LinkedInIcon} />}
        path='https://www.linkedin.com/company/powerbitips'
      />
    </Box>
  )
}
function NavigationSection(props: NavigationSectionProps) {
  const {title, children} = props
  const classes = useStyles()
  return (
    <div className={classes.navSection}>
      <Typography
        variant='overline'
        sx={{
          fontWeight: 800,
          opacity: 0.4,
        }}
      >
        {title}
      </Typography>
      {children}
    </div>
  )
}

function NavigationLinkSocials(props: NavLinkSocialProps) {
  const {icon, path} = props
  const classes = useStyles()
  const theme = useTheme()

  return (
    <IconButton
      className={classes.imageIcon}
      sx={{
        borderRadius: 3,
        margin: 1,
        '&:hover': {
          backgroundColor: alpha(theme.palette.secondary.main, 0.23),
        },
      }}
      href={path}
      component={Link}
    >
      {icon}
    </IconButton>
  )
}

function GetPath(path: string) {
  const last = path.charAt(path.length - 1) //Gets the last char
  if (last === '/') {
    path = path.substring(0, path.length - 1) //Removes last char
  }
  path = path.substring(7, path.length) //Chops off 'http://' 
  return path
}

function NavigationLink(props: NavLinkProps) {
  const {icon, path, title} = props
  //console.log("Path input is %s", path)
  const isTabSelected = window.location.href.includes(GetPath(path))
  const theme = useTheme()
  return (
    <ListItem
      sx={{
        borderRadius: 3,
        mb: 1,
        '&:hover': {
          backgroundColor: alpha(theme.palette.secondary.main, 0.23),
        },
      }}
      button
      selected={isTabSelected}
      href={path}
      component={Link}
    >
      {icon}
      <ListItemText sx={{ml: 1}} primary={title}></ListItemText>
    </ListItem>
  )
}

type NavLinkProps = {
  icon: JSX.Element
  path: string
  title: string
  
}

type NavigationSectionProps = {
  title: string
  children: ReactNode
}

type NavLinkSocialProps = {
  icon: JSX.Element
  path: string
}
