import {Theme} from '@mui/material'
import {makeStyles} from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  colorContainer: {
    // height: '30px',
    height: '100%',
    width: '100%',
    // overflow: 'hidden',
    display: 'flex',
    alignContent: 'stretch',
  },
  swatch: {
    height: '100%',
    width: '100%',
    minWidth: '24px',
  },
}))

export function Palette(props: PaletteProps) {
  const {palette} = props

  const classes = useStyles()
  return (
    <div className={classes.colorContainer}>
      {palette.map((color, index) => {
        return (
          <div
            key={`${color}` + index}
            className={classes.swatch}
            style={{background: color}}
          />
        )
      })}
    </div>
  )
}
type PaletteProps = {
  palette: string[]
}
