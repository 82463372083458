export const testPalettes = [
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
  {
    palette: [
      '#e74c3c',
      '#e67e22',
      '#f1c40f',
      '#2ecc71',
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#2c3e50',
    ],
    id: 1234,
  },
]
