import {Tab, Tabs} from '@mui/material'
import {Location} from 'history'
import React, {useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {sendGAPageView} from '../../utils/analytics-helper'

import {pages} from '../../pages'

export function TabNavigation(props: TabNavigationProps) {
  const {location} = props
  useEffect(() => {
    sendGAPageView(location.pathname)
  }, [location.pathname])
  let history = useHistory()

  return (
    <>
      <Tabs
        value={location.pathname}
        indicatorColor='primary'
        textColor='primary'
        centered
      >
        {pages.map((page, index) => (
          <Tab
            key={page.path}
            label={page.title}
            value={page.path}
            onClick={() => {
              history.push(page.path)
              sendGAPageView(page.path)
            }}
          />
        ))}
      </Tabs>
    </>
  )
}

export type TabNavigationProps = {
  location: Location
}
