import {PaletteObject} from '../../../models'
import {
  FETCH_DATA_COLORS_BEGIN,
  FETCH_DATA_COLORS_SUCCESS,
  FETCH_DATA_COLORS_FAILURE,
} from '../../actions'

export const paletteInitialState = {
  loading: false,
  error: null,
  palettes: [],
  continuationToken: null,
  dayModifier: -1,
}

export const dataColors = (state = paletteInitialState, action: any) => {
  switch (action.type) {
    case FETCH_DATA_COLORS_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      }
    }
    case FETCH_DATA_COLORS_SUCCESS: {
      const {palettes} = state
      let newPalettes: PaletteObject[] = palettes ? [...palettes] : []

      action.payload.response.data.results.forEach((result: any) => {
        let palette = JSON.parse(result.json)
        let id = result.rowKey
        newPalettes.push({palette: palette, id: id})
      })

      return {
        ...state,
        loading: false,
        error: null,
        palettes: newPalettes,
        continuationToken: action.payload.response.data.continuationToken,
        dayModifier: action.payload.response.data.dayModifier,
      }
    }
    case FETCH_DATA_COLORS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        palettes: [],
        continuationToken: null,
        dayModifier: -1,
      }
    }
    default:
      return state
  }
}
