import {createStore, applyMiddleware, compose, Store} from 'redux'
import thunk from 'redux-thunk'
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import rootReducer from '../reducers'

import {paletteInitialState} from '../reducers/query-results/palette-query-reducer'

const initialState = {
  dataColors: paletteInitialState,
}

export function configureStore(): Store<any> {
  let middleware = applyMiddleware(
    thunk,
    /// #if DEBUG
    reduxImmutableStateInvariant() // For development only! Remove for production
    /// #endif
  )
  //prettier-ignore
  // @ts-ignore
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(middleware)
  )

  return store
}
