import {CssBaseline, ThemeProvider} from '@mui/material'
// import {Provider as ReduxProvider} from 'react-redux'
import {Layout} from './components'
import {BrowserRouter as Router} from 'react-router-dom'
import {powerbi_tips_theme as theme} from './utils/powerbi_tips-theme'

// import {reduxStore} from './redux'

export default function App() {
  return (
    <Providers>
      <CssBaseline />
      <Layout />
    </Providers>
  )
}

function Providers(props: {children: any}) {
  return (
    <ThemeProvider theme={theme}>
      {/* <ReduxProvider store={reduxStore}> */}
      <Router>{props.children}</Router>
      {/* </ReduxProvider> */}
    </ThemeProvider>
  )
}
