import {AzureContinuationToken} from '../../models'

export const FETCH_DATA_COLORS_BEGIN = 'FETCH_DATA_COLORS_BEGIN'
export const FETCH_DATA_COLORS_SUCCESS = 'FETCH_DATA_COLORS_SUCCESS'
export const FETCH_DATA_COLORS_FAILURE = 'FETCH_DATA_COLORS_FAILURE'
const axios = require('axios').default

function requestPalettes(
  continuationToken: AzureContinuationToken | null,
  dayModifier: number,
  dispatch: any
) {
  let json = {continuationToken, dayModifier}
  let data = JSON.stringify(json)

  let config = {
    method: 'post',
    url: '/api/palettes',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  }

  axios(config)
    .then((response: FetchPaletteResponse) => {
      dispatch(fetchDataColorsSuccess(response))
      return response
    })
    .catch((error: Error) => {
      console.log('error with response from server requesting palettes', error)
      dispatch(fetchDataColorsFailure(error))
    })
}

export function fetchColors(
  continuationToken: AzureContinuationToken | null,
  dayModifier: number
) {
  return (dispatch: any) => {
    dispatch(fetchDataColorsBegin())

    return requestPalettes(continuationToken, dayModifier, dispatch)
  }
}

export const fetchDataColorsBegin = () => ({
  type: FETCH_DATA_COLORS_BEGIN,
})

export const fetchDataColorsSuccess = (response: FetchPaletteResponse) => ({
  type: FETCH_DATA_COLORS_SUCCESS,
  payload: {response},
})

export const fetchDataColorsFailure = (error: Error) => ({
  type: FETCH_DATA_COLORS_FAILURE,
  payload: {error},
})

export type FetchPaletteResponse = {
  results: any
  continuationToken: AzureContinuationToken | null
  dayModifier: number
}
