export type PbiTipsTool = {
  toolName: string
  assetName: string //example: Palettes
  url: string
}

export const ThemeGeneratorPalette: PbiTipsTool = {
  toolName: 'Theme Generator',
  assetName: 'Palette',
  url: 'https://themes.powerbi.tips',
}

export const ChartsTool: PbiTipsTool = {
  toolName: 'Charts Tool',
  assetName: 'Charts',
  url: 'https://charts.powerbi.tips',
}
