import {StrictMode} from 'react'
import ReactDOM from 'react-dom'

import {Provider} from 'react-redux'
import {configureStore} from './redux/store'
import App from './app'
import reportWebVitals from './reportWebVitals'

const store = configureStore()

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>,
  document.getElementById('root')
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
